
import axios from '../config/axios';

export const url = '/api/productossnywe'


export const getAllproductossnywe = ({
  token,
}) => new Promise((resolve, reject) => {
  if (token) {
    axios.get(`${url}//list/basic/673b5ffa9633993dfe9ef098`, {
      headers: {
        Authorization: token,
      }
    }).then((res) => {
      const {data} = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch((error) => reject(error));
  } else {
    reject({
      status: 'error',
      info: 'Token no definido',
    });
  }
});
      

