
import axios from 'axios';

var browserName = (function(agent) {
    switch (true) {
        case agent.indexOf("edge") > -1: return "Edge";
        case agent.indexOf("edg") > -1: return "Edge (Chromium)";
        case agent.indexOf("opr") > -1 && !!window.opr: return "Opera";
        case agent.indexOf("chrome") > -1 && !!window.chrome: return "Chrome";
        case agent.indexOf("trident") > -1: return "Internet Explorer";
        case agent.indexOf("firefox") > -1: return "Firefox";
        case agent.indexOf("safari") > -1: return "Safari";
        default: return "Otro";
    }
})(window.navigator.userAgent.toLowerCase());

export const loftySendVisitProject = async () => {
    axios.post('https://backend.loftyapps.online/log-visit', {
      projectId: '659ed851d6fc4f715d3a0d0c',
      browser: browserName
    });
}
    