
import React, {useEffect, useState} from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import AOS from 'aos';
import {connect} from 'react-redux';
import {addLoftyUser, deleteLoftytoken} from './actions/auth.actions';
import { loftySendVisitProject } from './util/visitsprojects';
import { loftyGetLoggedUser } from './api/user.api';

// pages
import paginaprincipal from './pages/paginaprincipal/paginaprincipal'


// css
import './App.css';
import 'aos/dist/aos.css';

AOS.init();

function initSwiper() {
  // eslint-disable-next-line no-undef
  const swiper = new Swiper('.mySwiper', {
    spaceBetween: 30,
    centeredSlides: true,
    autoplay: false,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  });
  // console.log('swiper :>> ', swiper);
}

const App = ({loftytoken, addLoftyUserApp, deleteLoftytokenApp}) => {

  // agregando iniciación para el Swipper
  useEffect(() => {
    setTimeout(() => {
      initSwiper();
    }, 2000);
  }, []);

  useEffect(() => { loftySendVisitProject() }, []);

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const handleData = async () => {
      try {
        const res = await loftyGetLoggedUser({ token: loftytoken });
        addLoftyUserApp(res.user);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        deleteLoftytokenApp();
      }
    }
    handleData();
  }, []);
  if (loading) {
    return <div>Loading ...</div>
  }


  return (
    <Router>
      <Switch>
			<Route exact path="/" component={paginaprincipal} />

      </Switch>
    </Router>
  );
}

const mapStateToProps = (state) => state.auth;

const mapDispatchToProps = (dispatch) => ({
  addLoftyUserApp: (user) => dispatch(addLoftyUser(user)),
  deleteLoftytokenApp: () => dispatch(deleteLoftytoken()),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);

